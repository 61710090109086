import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Início
  {
    path: '/overview/',
    Component: lazy(() => import('pages/overview')),
    exact: true,
  },
  // Acadêmico
  // Turmas
  {
    path: '/classes/',
    Component: lazy(() => import('pages/classes/overview')),
    exact: true,
  },
  {
    path: '/classes/:classId/',
    Component: lazy(() => import('pages/classes/class')),
    exact: false,
  },
  // Questões
  {
    path: '/questions/',
    Component: lazy(() => import('pages/questions')),
    exact: true,
  },
  {
    path: '/questions/:questionId/',
    Component: lazy(() => import('pages/questions/question')),
    exact: false,
  },
  // Professores
  {
    path: '/teachers/',
    Component: lazy(() => import('pages/teachers')),
    exact: true,
  },
  // Estatísticas
  {
    path: '/statistics/',
    Component: lazy(() => import('pages/statistics/overview')),
    exact: true,
  },
  {
    path: '/statistics/reports/',
    Component: lazy(() => import('pages/statistics/reports')),
    exact: false,
  },
  // Comunicação
  // Notificações
  {
    path: '/notifications/',
    Component: lazy(() => import('pages/notifications')),
    exact: true,
  },
  {
    path: '/notifications/create',
    Component: lazy(() => import('pages/notifications/create')),
    exact: true,
  },
  // Simulados
  {
    path: '/exams/',
    Component: lazy(() => import('pages/exams')),
    exact: true,
  },
  {
    path: '/exams/create/',
    Component: lazy(() => import('pages/exams/create')),
    exact: true,
  },
  {
    path: '/exams/questions/',
    Component: lazy(() => import('pages/exams/questions')),
    exact: true,
  },
  {
    path: '/exams/statistics/',
    Component: lazy(() => import('pages/exams/statistics')),
    exact: true,
  },
  {
    path: '/exams/questions/create/',
    Component: lazy(() => import('pages/exams/questions/create')),
    exact: true,
  },
  {
    path: '/exams/questions/:questionId/',
    Component: lazy(() => import('pages/exams/questions/view')),
    exact: false,
  },
  {
    path: '/exams/:examId/',
    Component: lazy(() => import('pages/exams/view')),
    exact: false,
  },
  // Monitorias
  {
    path: '/bookings/',
    Component: lazy(() => import('pages/bookings/room')),
    exact: true,
  },
  {
    path: '/bookings/availabilities/',
    Component: lazy(() => import('pages/bookings/availabilities')),
    exact: true,
  },
  {
    path: '/bookings/create/',
    Component: lazy(() => import('pages/bookings/room/create')),
    exact: true,
  },
  {
    path: '/bookings/statistics/',
    Component: lazy(() => import('pages/bookings/statistics')),
    exact: true,
  },
  {
    path: '/bookings/configs/',
    Component: lazy(() => import('pages/bookings/configs')),
    exact: true,
  },
  {
    path: '/bookings/:bookingId/',
    Component: lazy(() => import('pages/bookings/room/view')),
    exact: true,
  },
  {
    path: '/bookings/:bookingId/edit/',
    Component: lazy(() => import('pages/bookings/room/edit')),
    exact: true,
  },
  {
    path: '/bookings/credits/users/:userId',
    Component: lazy(() => import('pages/bookings/credits/users/user')),
    exact: true,
  },
  // Administração
  // Usuários
  {
    path: '/admin/users/',
    Component: lazy(() => import('pages/admin/users')),
    exact: true,
  },
  {
    path: '/admin/users/create/',
    Component: lazy(() => import('pages/admin/users/create')),
    exact: true,
  },
  {
    path: '/admin/users/import/',
    Component: lazy(() => import('pages/admin/users/import')),
    exact: true,
  },
  {
    path: '/admin/users/:userId/',
    Component: lazy(() => import('pages/admin/users/user')),
    exact: false,
  },
  // Finance
  {
    path: '/finance/users',
    Component: lazy(() => import('pages/finance/users')),
    exact: false,
  },
  {
    path: '/finance/questions/',
    Component: lazy(() => import('pages/finance/questions')),
    exact: true,
  },
  {
    path: '/finance/questions/:questionId/',
    Component: lazy(() => import('pages/questions/question')),
    exact: false,
  },
  {
    path: '/finance/earnings-report/',
    Component: lazy(() => import('pages/finance/earnings-report')),
    exact: true,
  },
  // Users
  {
    path: '/users/:userId',
    Component: lazy(() => import('pages/users/user')),
    exact: false,
  },
  // Marketing
  {
    path: '/marketing/leads',
    Component: lazy(() => import('pages/marketing/leads')),
    exact: false,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  role: user.role,
})

function Router({ history, routerAnimation, role }) {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Redirect
                          to={
                            role === 'EXAMS_ONLY_GROUP_ADMIN' ? '/exams/statistics/' : '/overview/'
                          }
                        />
                      )}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Component />
                            </Suspense>
                          </div>
                        )}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
