import React from 'react'
import { Tabs } from 'antd'
import style from './style.module.scss'

import backend from '../../../../../services/backend'

const { TabPane } = Tabs

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return `${Math.floor(interval)} anos atrás`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return `${Math.floor(interval)} meses atrás`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return `${Math.floor(interval)} dias atrás`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `${Math.floor(interval)} horas atrás`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `${Math.floor(interval)} minutos atrás`
  }
  return `${Math.floor(seconds)} segundos atrás`
}

const getNotifications = (notifications, notifyView) => {
  const nots = notifications.map(notification => (
    <li className="mb-3">
      <div className={style.head}>
        <p className={style.title}>
          <strong className={notification.type === 'ALERT' ? 'text-danger' : ''}>
            {notification.title}
          </strong>
        </p>
        <time
          className={style.time}
          dateTime={notification.created_at}
          title={`${new Date(Date.parse(notification.created_at)).toLocaleDateString()} ${new Date(
            Date.parse(notification.created_at),
          ).toLocaleTimeString()}`}
        >
          {timeSince(Date.parse(notification.created_at))}
        </time>
      </div>
      <p>{notification.description}</p>
    </li>
  ))
  if (nots.length === 0) {
    return <div className="text-center mb-3 py-4 bg-light rounded">Nenhuma notificação</div>
  }

  nots.forEach(n => notifyView(n.key))

  return (
    <div className="text-gray-6">
      <ul className="list-unstyled">{nots}</ul>
    </div>
  )
}

function NotificationsList(props) {
  const { notifications = [] } = props

  const notifyView = id => {
    backend.post(`/analytics/notifications/${id}/view`)
  }

  return (
    <div>
      <Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1">
        <TabPane tab="Notificações" key="1">
          {getNotifications(notifications, notifyView)}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default NotificationsList
