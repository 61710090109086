import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import apiClient from '../backend'

const firebaseConfig = {
  apiKey: 'AIzaSyAaGgbdTuPQxXGagq6PgzZ2dS8ky-iWpOc',
  authDomain: 'stift-production.firebaseapp.com',
  databaseURL: 'https://stift-production.firebaseio.com',
  projectId: 'stift-production',
  storageBucket: 'stift-production.appspot.com',
  messagingSenderId: '174305833440',
  appId: '1:174305833440:web:a2839a2f389f48edbafb85',
  measurementId: 'G-H0HN6BS4PF',
}

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebaseAuth
    .signInWithPopup(provider)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const apiData = await apiClient.get('/user')
            let userRole = apiData.data.type
            const isExamsOnly = apiData.data.groups.every(g => g.business_model === 'EXAMS_ONLY')
            const userLocation = apiData.data.location
            let userType = ''

            if (isExamsOnly) {
              if (userRole === 'GROUP_ADMIN' || userRole === 'ADMIN') {
                userRole = 'EXAMS_ONLY_GROUP_ADMIN'
              }
            }

            switch (userRole) {
              case 'GROUP_ADMIN':
                userType = 'Coordenador'
                break
              case 'EXAMS_ONLY_GROUP_ADMIN':
                userType = 'Coordenador'
                break
              case 'ADMIN':
                userType = 'Administrador'
                break
              case 'TEACHER':
                userType = 'Professor'
                break
              case 'PEDAGOGUE':
                userType = 'Pedagogo'
                break
              case 'STUDENT':
                userType = 'Aluno'
                break
              default:
                userType = userRole
                break
            }

            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: `${apiData.data.first_name} ${apiData.data.last_name}`,
              role: userRole,
              location: userLocation,
              type: userType,
              schools: apiData.data.schools,
              avatar: apiData.data.profile_photo_url,
              modules: apiData.data.modules,
              isExamsOnly,
            })
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}
